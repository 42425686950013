import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Logout } from "components/atoms/logout";
import { useForm, SubmitHandler } from "react-hook-form";

type FormProps = {
    handleSwitchPolygon: () => Promise<void>;
    switching: boolean;
};

type Inputs = {};

export const FormSwitchPolygon: React.VFC<FormProps> = ({ handleSwitchPolygon, switching }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (): Promise<void> => await handleSwitchPolygon();

  return (
    <>
      <Text
        as="h4"
        color="rgb(45, 55, 72)"
        fontSize={{ base: "lg", md: "3xl" }}
        lineHeight="1.235"
        fontWeight={700}
        mb={3}
      >
        {t("Deposit Title")}
      </Text>
      <Text
        as="p"
        py="2"
        fontSize={{ base: "sm", md: "1.25rem" }}
        lineHeight="1.6"
        color="rgba(100, 110, 115, 0.7)"
        fontWeight={400}
      >
        {t("Deposit Description")}
      </Text>
      <Flex
        as="form"
        alignItems="center"
        justifyContent="center"
        direction="column"
        onSubmit={handleSubmit(onSubmit)}
      >
          <Text fontWeight={"bold"}>{t("Switch to Polygon")}</Text>
        <Button
          type="submit"
          size="md"
          py={6}
          mt={4}
          w="100%"
          maxW="400px"
          color="white"
          background="#fa0079"
          isLoading={switching}
          isDisabled={switching}
          _hover={{ bg: "#ae0054" }}
          _focus={{ bg: "#ae0054" }}
          _active={{ bg: "#ae0054" }}
        >
          {t("Switch Polygon Button")}
        </Button>
        <Logout />
      </Flex>
    </>
  );
};
