import React, { useContext } from "react";
import { Box } from "@chakra-ui/react";
import { Header } from "components/organisms/header";
import { Main } from "components/organisms/main";
import { Footer } from "components/organisms/footer";
import { AuthContext } from "contexts/auth.context";
import { Deposit } from "components/organisms/deposit";
import { Flex } from "@chakra-ui/react";
import "i18n";

const IndexPage: React.VFC = () => {
  const { value } = useContext(AuthContext);

  return (
    <Box
      w="100%"
      h="100%"
      bgGradient="linear(to-r, rgba(255, 255, 255, 0), rgb(247, 249, 252))"
      id="app"
    >
      <Flex
        minH="100vh"
        boxSizing="border-box"
        mx="auto"
        my="0"
        textAlign="center"
        flexDirection="column"
        py="0"
      >
        <Header />
        {value?.isLoggedIn ? <Deposit /> : <Main />}
        <Footer />
      </Flex>
    </Box>
  );
};

export default IndexPage;
