import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Web3Connector from "providers/web3-connector";
import { useState } from "react";

export const useSwitchPolygon = (): {
    handleSwitchPolygon: () => Promise<void>;
    switching: boolean;
    isPolygon: boolean;
} => {
    const toast = useToast();
    const { t } = useTranslation();
    const [switching, setSwitching] = useState<boolean>(false);
    const [isPolygon, setIsPolygon] = useState<boolean>(Web3Connector.isPolygon())

    const handleSwitchPolygon = async (): Promise<void> => {
        if (await Web3Connector.isPolygonAsync()) {
            setIsPolygon(true);
            return;
        }
        setSwitching(true);

        try {
            await Web3Connector.switchPolygonNetwork();
            setIsPolygon(true);

            toast({
                title: t("Switched to Polygon network"),
                position: "top",
                status: "success",
                isClosable: true,
            });
        } catch (e: any) {
            console.error(e);
            toast({
                title: t("Failed to switch to Polygon network"),
                position: "top",
                status: "error",
                isClosable: true,
            });
        } finally {
            setSwitching(false);
        }
    };

    return { handleSwitchPolygon: handleSwitchPolygon, switching, isPolygon };
};
