import React from "react";
import { MainContainer } from "components/atoms/main-container";
import { useDeposit } from "hooks/use-deposit";
import { Complete } from "./complete";
import { Form } from "./form";
import { FormSwitchPolygon } from "./form-sw-polygon";
import { useSwitchPolygon } from "../../../hooks/use-switch-polygon";
import { getDepositArgs } from "../../../common/get-params";
import { useSelectUser } from "../../../hooks/use-select-user";

export const Deposit: React.VFC = () => {
  const { handleDeposit, loading, completed, txHash } = useDeposit();
  const { handleSwitchPolygon, switching, isPolygon } = useSwitchPolygon();
  const args = getDepositArgs();
  const { handleGetUserList, handleSelectUser, handleGetUser, selected, selectedUser, userList }  = useSelectUser(args.userId);

  return (
    <MainContainer>
      {completed ? (
        <Complete txHash={txHash}/>
      ) : (isPolygon ? (
        <Form handleGetUserList={handleGetUserList} handleSelectedUser={handleSelectUser} handleGetUser={handleGetUser}
              selected={selected} userList={userList} selectedUser={selectedUser}
              handleDeposit={handleDeposit} loading={loading} />
      ) : (
          <FormSwitchPolygon handleSwitchPolygon={handleSwitchPolygon} switching={switching} />
      ))}
    </MainContainer>
  );
};
