import { GraphQLClient } from 'graphql-request';
import { User } from "../interfaces";

class GraphqlConnector {
  private readonly graphqlClient: GraphQLClient;

  constructor() {
    this.graphqlClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_ENDPOINT!);
  }

  async getUsersByAccount(account: string): Promise<User[]> {
    const query = `query {
  users(
    query: "${account}",
    pagingOptions: {
      limit: 10
    }
  ) {
    id,
    account,
    name,
    icon
  }
}`;

    const response = await this.graphqlClient.request(query);

    return response.users.map((user: any) => ({
      id: user.id,
      account: user.account,
      name: user.name,
      icon: user.icon,
    }));
  }

  async getUser(userId: number): Promise<User | undefined> {
    const query = `query {
  user(
    userId: ${userId}
  ) {
    id,
    account,
    name,
    icon
  }
}`;
    const response = await this.graphqlClient.request(query);

    if (!response.user) {
      return undefined
    }
    return {
      id: response.user.id,
      account: response.user.account,
      name: response.user.name,
      icon: response.user.icon,
    };
  }
}

export default new GraphqlConnector();
