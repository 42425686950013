import { useToast } from "@chakra-ui/react";
import { useState, useCallback } from "react";
import { useTranslation, TFunction } from "react-i18next";
import Web3Connector from "providers/web3-connector";

const getDepositErrorMsg = (
  t: TFunction<"translation", undefined>,
  msg: string
): string => {
  if (msg === "invalid network") return t("Invalid network");
  return t("Failure Deposit");
};

export const useDeposit = (): {
  handleDeposit: (userId: number, cureCosMultiNFTAddress: string, tokenId: number, amount: number) => Promise<void>;
  loading: boolean;
  completed: boolean;
  txHash: string;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>("");
  const toast = useToast();
  const { t } = useTranslation();

  const handleDeposit = useCallback(async (userId: number, cureCosMultiNFTAddress: string, tokenId: number, amount: number): Promise<void> => {
    setLoading(true);

    try {
      if (!await Web3Connector.isPolygonAsync()) {
        throw Error("invalid network");
      }
      const lowerCureCosNFTAddress = cureCosMultiNFTAddress.toLowerCase();

      const txReceipt = await Web3Connector.deposit(userId, lowerCureCosNFTAddress, tokenId, amount);
      setTxHash(txReceipt.transactionHash);

      toast({
        title: t("Success Deposit"),
        position: "top",
        duration: 9000,
        status: "success",
        isClosable: true,
      });

      setCompleted(true);
    } catch (error: any) {
      const message = getDepositErrorMsg(t, error.message);

      toast({
        title: message,
        position: "top",
        status: "error",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [t, toast]);

  return { handleDeposit: handleDeposit, loading, completed, txHash };
};
