import React from "react";
import {Input, Link, Text, VStack} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Logout } from "components/atoms/logout";
import web3 from "web3";

type ResultProps = {
    txHash: string;
};

export const Complete: React.VFC<ResultProps> = ({txHash}) => {
  const { t } = useTranslation();

  return (
    <>
      <Text
        as="h4"
        color="rgb(45, 55, 72)"
        fontSize={{ base: "lg", md: "3xl" }}
        lineHeight="1.235"
        fontWeight={700}
        mb={3}
      >
        {t("Thank you Deposit")}
      </Text>
        <VStack>
            <Link
                as="a"
                py="2"
                href={`${process.env.REACT_APP_WEB3_BLOCK_EXPLORER_URL}tx/${txHash}`}
                target="_blank"
                fontSize={{ base: "sm", md: "1.25rem" }}
                lineHeight="1.6"
                fontWeight={400}
            >
                {`Transaction hash: ${txHash}`}
            </Link>
        </VStack>
      <Logout />
    </>
  );
};
