import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import { useLogin } from "hooks/use-login";
import { MainContainer } from "components/atoms/main-container";
import { useTranslation } from "react-i18next";

export const Main: React.VFC = () => {
  const { handleSignIn } = useLogin();
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Text
        as="h4"
        color="rgb(45, 55, 72)"
        fontSize={{ base: "lg", md: "3xl" }}
        lineHeight="1.235"
        fontWeight={700}
        mb={3}
      >
        {t("Deposit Token")}
      </Text>
      <div id="app" />
      <Text
        as="p"
        py="2"
        fontSize={{ base: "sm", md: "1.25rem" }}
        lineHeight="1.6"
        color="rgba(100, 110, 115, 0.7)"
        fontWeight={400}
      >
        {t("Description Deposit")}
      </Text>
      <Flex alignItems="center" justifyContent="center">
        <Button
          size="md"
          py={6}
          mt={4}
          w="100%"
          maxW="400px"
          color="white"
          background="#fa0079"
          _hover={{ bg: "#ae0054" }}
          _focus={{ bg: "#ae0054" }}
          _active={{ bg: "#ae0054" }}
          onClick={handleSignIn}
        >
          {t("Connect to a wallet")}
        </Button>
      </Flex>
    </MainContainer>
  );
};
