import Web3 from "web3";
import {AbiItem} from "web3-utils";
import DepositAbi from "contracts/DepositAbi.json";
import NFTAbi from "contracts/NFTAbi.json";
import Web3Modal from "web3modal";
import {ENGLISH} from "constant";
import {TransactionReceipt} from "web3-core";

class Web3Connector {
  private _web3: Web3 | null = null;
  private _contract: any;
  public _provider: any;
  private _web3Modal: Web3Modal | undefined;
  public _address: string | null = null;
  private _currentChainId: number = 0;

  async activate(language: string): Promise<void> {
    const providerOptions = Web3Connector.getProvider(language);
    try {
      this._web3Modal = new Web3Modal({
        network: process.env.REACT_APP_WEB3_NETWORK_NAME,
        cacheProvider: false,
        providerOptions,
      });
      this._provider = await this._web3Modal.connect();
      this._web3 = new Web3(this._provider);

      this._contract = new this._web3.eth.Contract(
          DepositAbi as AbiItem[],
          process.env.REACT_APP_CONTRACT_ADDRESS
      );

      this._address = await this.getAddress();
      this._currentChainId = await this._web3.eth.net.getId();
    } catch (error) {
      console.error(error);

      throw new Error();
    }
  }

  async getWeb3(): Promise<Web3> {
    return this._web3!;
  }

  async getAddress(): Promise<string> {
    const accounts = await this._web3!.eth.getAccounts();

    return accounts[0];
  }

  async switchPolygonNetwork() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: process.env.REACT_APP_WEB3_NETWORK_CHAIN_ID,
          },
        ],
      });
    } catch (e: any) {
      switch (e.code) {
        case 4902:
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: process.env.REACT_APP_WEB3_NETWORK_CHAIN_ID,
                chainName: process.env.REACT_APP_WEB3_NETWORK_NAME,
                nativeCurrency: {
                  name: "Matic",
                  symbol: "MATIC",
                  decimals: 18,
                },
                rpcUrls: [process.env.REACT_APP_WEB3_RPC_URL],
                blockExplorerUrls: [process.env.REACT_APP_WEB3_BLOCK_EXPLORER_URL],
              },
            ],
          });
          break;
        default:
          throw e;
      }
    }
  }

  isPolygon() {
    return this._currentChainId === parseInt(process.env.REACT_APP_WEB3_NETWORK_CHAIN_ID || "0", 16);
  }

  async isPolygonAsync() {
    this._currentChainId = await this._web3!.eth.net.getId();
    return this.isPolygon();
  }

  async deposit(userId: number, cureCosMultiNFT: string, tokenId: number, amount: number): Promise<TransactionReceipt> {
    const address = await this.getAddress();
    return await this._contract.methods
        .deposit(userId, cureCosMultiNFT, tokenId, amount)
        .send({from: address});
  }

  async isDepositAvailable(cureCosMultiNFT: string): Promise<boolean> {
    try {
      const address = await this.getAddress();
      return await this._contract.methods
        .isDepositAvailable(cureCosMultiNFT)
        .call({from: address});
    } catch {
      return false;
    }
  }

  async getTokenAmount(cureCosMultiNFT: string, tokenId: number): Promise<number> {
    try {
      const address = await this.getAddress();
      const nftContract = new this._web3!.eth.Contract(
        NFTAbi as AbiItem[],
        cureCosMultiNFT
      );
      return await nftContract.methods
        .balanceOf(address, tokenId)
        .call({from: address});
    } catch {
      return 0;
    }
  }

  async disconnect(): Promise<void> {
    if (this._provider?.close) {
      await this._provider.close();

      this._web3 = null;
      this._provider = null;
    }
  }

  static getProvider(language: string) {
    return {
      injected: {
        display: {
          description:
            language === ENGLISH
              ? "Connect to your MetaMask Wallet"
              : "MetaMask Walletに接続します。",
        },
        package: null,
      },
    };
  }
}

export default new Web3Connector();
