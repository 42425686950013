// storage
export const USER_AUTH = "USER_AUTH";

// others
export const ENGLISH = 'en';
export const JAPANESE = "ja";

// URL-params
export const URL_PARAM_NFT_TARGET_ADDRESS = 'userId';
export const URL_PARAM_NFT_CONTRACT_ADDRESS = 'cureCosMultiNFTAddress';
export const URL_PARAM_NFT_TOKEN_ID = 'tokenId';
export const URL_PARAM_NFT_AMOUNT = 'amount';

// Error
export const ERROR_ALREADY_CLAIMED = "already claimed";
export const ERROR_ADDRESS_NOT_FOUND = "Not Found your address";
