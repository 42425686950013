import { DepositArgs } from "../interfaces";
import { URL_PARAM_NFT_AMOUNT, URL_PARAM_NFT_CONTRACT_ADDRESS, URL_PARAM_NFT_TARGET_ADDRESS, URL_PARAM_NFT_TOKEN_ID } from "../constant";

export const getDepositArgs = (): DepositArgs => {
    const params = new URLSearchParams(window.location.search);
    const userId = parseInt(params.get(URL_PARAM_NFT_TARGET_ADDRESS) || '0');
    const cureCosMultiNFTAddress = params.get(URL_PARAM_NFT_CONTRACT_ADDRESS) || '';
    const tokenId = parseInt(params.get(URL_PARAM_NFT_TOKEN_ID) || '0');
    const amount = parseInt(params.get(URL_PARAM_NFT_AMOUNT) || '0');

    return {
        userId,
        cureCosMultiNFTAddress,
        tokenId,
        amount,
    };
}
