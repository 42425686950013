import { ENGLISH, JAPANESE } from 'constant';

export const config = {
  language: {
    defaultLanguage: ENGLISH,
    languages: [ENGLISH, JAPANESE]
  },
  siteLink: 'https://curecos.net/',
  socialLink: {
    facebook: "https://www.facebook.com/CureCosPlus",
    twitter: 'https://twitter.com/CureCosPlus"',
    instagram: "https://www.instagram.com/curecosplus/",
    weibo: "https://weibo.com/curecosplus",
  },
  textLink: {
    site: "https://curecos.net/",
    howToUse: {
      'jp': 'https://nft-deposit.curecos.net/docs/HowtoDepositJP.pdf',
      'en': 'https://nft-deposit.curecos.net/docs/HowtoDepositEN.pdf',
    },
    aboutUs: "https://corp.curecos.com/",
    contactUs: "https://curecos.net/settings/contact",
    privacyPolicy: "https://curecos.net/settings/privacy",
  },
};
