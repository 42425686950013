import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { User } from "../interfaces";
import GraphqlConnector from "../providers/graphql-connector";

export const useSelectUser = (userId: number): {
  handleGetUserList: (account: string) => Promise<void>;
  handleSelectUser: (userId: number) => void;
  handleGetUser: (userId: number) => Promise<void>;
  selected: boolean;
  selectedUser: User | undefined;
  userList: User[];
} => {
  const toast = useToast();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [userList, setUserList] = useState<User[]>([]);
  let userMap: {[key: number]: User} = {};

  const handleGetUserList = async (account: string) => {
    try {
      if (account === "") {
        setUserList([]);
        userMap = {}
        return;
      }
      const users = await GraphqlConnector.getUsersByAccount(account);
      setUserList(users);
      userMap = {}
      users.forEach((user) => {
        userMap[user.id] = user;
      })
    } catch (e) {
      toast({
        title: t("Failed to get users"),
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  };
  const handleSelectUser = (userId: number) => {
    const target = userMap[userId];
    setSelectedUser(target);
    setSelected(target !== undefined);
  };
  const handleGetUser = async (userId: number) => {
    try {
      const target = await GraphqlConnector.getUser(userId);
      setSelectedUser(target);
      setSelected(target !== undefined);
    } catch {
      setSelectedUser(undefined);
      setSelected(false);
    }
  };

  return {
    handleGetUserList,
    handleSelectUser,
    handleGetUser,
    selected,
    selectedUser,
    userList
  }
}
