import React, { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Text,
  Button,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Box,
  StackDivider,
  Image,
  Divider,
  FormHelperText,
  InputRightElement,
  InputGroup, InputRightAddon
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Logout } from "components/atoms/logout";
import { useForm, SubmitHandler } from "react-hook-form";
import web3 from "web3";
import { getDepositArgs } from "../../../common/get-params";
import { User } from "../../../interfaces";
import Web3Connector from "../../../providers/web3-connector";
import { CheckIcon, WarningTwoIcon } from "@chakra-ui/icons";

type FormProps = {
  handleGetUserList: (account: string) => Promise<void>;
  handleSelectedUser: (userId: number) => void;
  handleGetUser: (userId: number) => Promise<void>;
  selected: boolean;
  userList: User[];
  selectedUser: User | undefined;
  handleDeposit: (userId: number, cureCosMultiNFTAddress: string, tokenId: number, amount: number) => Promise<void>;
  loading: boolean;
};

type Inputs = {
    cureCosMultiNFTAddress: string;
    tokenId: number;
    amount: number;
};

export const Form: React.VFC<FormProps> = ({ handleGetUserList, handleSelectedUser, handleGetUser, selected, userList, selectedUser, handleDeposit, loading }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const [validContract, setValidContract] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(0);

  const onSubmit: SubmitHandler<Inputs> = async ({ cureCosMultiNFTAddress, tokenId, amount}: Inputs): Promise<void> =>
    await handleDeposit(selectedUser?.id || 0, cureCosMultiNFTAddress, tokenId, amount);

  const getUserList = useCallback((e) => {
    const f = async () => {
      await handleGetUserList(e.target.value);
    }
    f();
  }, []);

  const onSelectUser = useCallback((e) => {
    handleSelectedUser(parseInt(e.target.id));
  }, []);

  const onChangeSelectedUser = useCallback(() => {
    handleSelectedUser(0);
  }, []);

  const validateContractAddress = async (cureCosMultiNFT: string) => {
    if (web3.utils.isAddress(cureCosMultiNFT)) {
      const valid = await Web3Connector.isDepositAvailable(cureCosMultiNFT);
      setValidContract(valid);
      return valid;
    } else {
      setValidContract(false);
      return false;
    }
  }

  const getTokenAmount = async (cureCosMultiNFT: string, tokenId: number) => {
    const amount = await Web3Connector.getTokenAmount(cureCosMultiNFT, tokenId);
    setTokenAmount(amount);
    return amount;
  }

  useEffect((): void => {
      const args = getDepositArgs();
      if (args.userId > 0) {
        const f = async () => {
          await handleGetUser(args.userId);
        }
        f();
      }
      setValue("cureCosMultiNFTAddress", args.cureCosMultiNFTAddress);
      setValue("tokenId", args.tokenId);
      setValue("amount", args.amount);
    if (web3.utils.isAddress(args.cureCosMultiNFTAddress)) {
      validateContractAddress(args.cureCosMultiNFTAddress)
        .then((valid) => {
        if (valid && args.tokenId > 0 ) {
          getTokenAmount(args.cureCosMultiNFTAddress, args.tokenId)
        }
      });
    }

  }, [setValue]);

  return (
    <>
      <Text
        as="h4"
        color="rgb(45, 55, 72)"
        fontSize={{ base: "lg", md: "3xl" }}
        lineHeight="1.235"
        fontWeight={700}
        mb={3}
      >
        {t("Deposit Title")}
      </Text>
      <Text
        as="p"
        py="2"
        fontSize={{ base: "sm", md: "1.25rem" }}
        lineHeight="1.6"
        color="rgba(100, 110, 115, 0.7)"
        fontWeight={400}
      >
        {t("Deposit Description")}
      </Text>

      {
        !selected ? (
          <>
            <Text fontWeight={"bold"}>{t("Select user")}</Text>
            <Flex justifyContent="center" my={3}>
              <VStack alignItems="center" justifyContent="center" w="80%">
                <FormControl w="80%">
                  <Input
                    id="account"
                    type="text"
                    placeholder={t("Account")}
                    onChange={getUserList}/>
                </FormControl>
                {
                  userList.length > 0 ? (<Divider w="80%"/>) : (<></>)
                }
                <VStack divider={<StackDivider borderColor='gray.200' />}
                        spacing={2}
                        my={2}
                        align='stretch'
                        w="60%">
                  {
                    userList.map((user) => {
                      return (
                        <Box key={user.id.toString()} id={user.id.toString()} as="button" className="user-item" onClick={onSelectUser}>
                          <Flex direction="row" style={{pointerEvents: "none", alignItems: "center"}}>
                            <Image boxSize="32px" src={user.icon ?? undefined} style={{margin: "5px"}}/>
                            <div style={{marginLeft: "10px"}}>
                              <span>{user.name}</span><br/>
                              <span style={{color: "#888"}}>@{user.account}</span>
                            </div>
                          </Flex>
                        </Box>
                      )
                    })
                  }
                </VStack>
              </VStack>
            </Flex>
          </>
        ) : <></>
      }

      {
        selected && selectedUser !== undefined ? (
          <Flex justifyContent="center">
            <VStack
              as="form"
              alignItems="center"
              justifyContent="center"
              onSubmit={handleSubmit(onSubmit)}
              spacing={3}
              my={2}
              w="80%"
            >
              <VStack my={2}>
                <Box id={selectedUser.id.toString()} style={{padding: "5px"}}>
                  <Flex direction="row" style={{alignItems: "center"}}>
                    <Image boxSize="32px" src={selectedUser.icon ?? undefined} style={{margin: "5px"}}/>
                    <div style={{marginLeft: "10px"}}>
                      <span>{selectedUser.name}</span><br/>
                      <span style={{color: "#888"}}>@{selectedUser.account}</span>
                    </div>
                    <Button type="button" mx={5} onClick={onChangeSelectedUser}>{t("Change")}</Button>
                  </Flex>
                </Box>
              </VStack>

              <FormControl isRequired>
                <FormLabel htmlFor="cureCosMultiNFTAddress" fontWeight="bold">{t("CureCosMultiNFT Address")}</FormLabel>
                <InputGroup>
                  <Input
                    id="cureCosMultiNFTAddress"
                    type="text"
                    placeholder="0x....."
                    {...register("cureCosMultiNFTAddress", {
                      required: t("CureCosMultiNFT Address is required") as string,
                      validate: () => {
                        return validContract
                          ? true
                          : (t("CureCosMultiNFT Address format error") as string);
                      },
                      onChange: () => {
                        validateContractAddress(getValues("cureCosMultiNFTAddress")).then((valid) => {
                          if (valid && getValues("tokenId") > 0) {
                            getTokenAmount(getValues("cureCosMultiNFTAddress"), getValues("tokenId"));
                          }
                        });
                      },
                    })}
                  />
                  <InputRightElement children={
                    validContract ? <CheckIcon color="green.500" /> : <WarningTwoIcon color="red.500" />
                  }/>
                </InputGroup>
                {errors.cureCosMultiNFTAddress && (
                  <FormHelperText color="red.500">{errors.cureCosMultiNFTAddress.message}</FormHelperText>
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="tokenId" fontWeight="bold">{t("Token ID")}</FormLabel>
                <Input
                  id="tokenId"
                  type="text"
                  {...register("tokenId", {
                    required: t("Token ID is required") as string,
                    validate: (tokenId: number) => {
                      return tokenId > 0
                        ? true
                        : (t("Token ID format error") as string);
                    },
                    onChange: () => {
                      if (validContract) {
                        getTokenAmount(getValues("cureCosMultiNFTAddress"), getValues("tokenId"));
                      }
                    }
                  })}
                />
                {errors.tokenId && (
                  <FormHelperText color="red.500">{errors.tokenId.message}</FormHelperText>
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="amount" fontWeight="bold">{t("Amount")}</FormLabel>
                <InputGroup>
                  <Input
                    id="amount"
                    type="number"
                    {...register("amount", {
                      required: t("Amount is required") as string,
                      validate: (amount: number) => {
                        return amount > 0 && amount < tokenAmount
                          ? true
                          : (t("Amount format error") as string);
                      },
                    })}
                  />
                  <InputRightAddon children={`${t("Current possession")}: ${tokenAmount}`}/>
                </InputGroup>
                {errors.amount && (
                  <FormHelperText color="red.500">{errors.amount.message}</FormHelperText>
                )}
              </FormControl>
              <VStack spacing={3} my={2} width="100%">
                <Button
                  type="submit"
                  size="md"
                  py={6}
                  mt={4}
                  w="100%"
                  maxW="400px"
                  color="white"
                  background="#fa0079"
                  isLoading={loading}
                  isDisabled={loading}
                  _hover={{ bg: "#ae0054" }}
                  _focus={{ bg: "#ae0054" }}
                  _active={{ bg: "#ae0054" }}
                >
                  {t("Deposit Button")}
                </Button>
                <Logout />
              </VStack>
            </VStack>
          </Flex>
        ) : (
          <VStack spacing={3} my={2} width="100%">
            <Logout />
          </VStack>
        )
      }
    </>
  );
};
